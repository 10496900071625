//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: String,
      default: ''
    },
    fill: {
      type: String,
      default: '#FF3D68'
    }
  },
  computed: {
    lastColorFill () {
      return this.fill !== '#FF3D68' ? this.fill : '#D4004D'
    }
  }
}
