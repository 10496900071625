const qs = require('qs');
export default ($config, $axios) => ({
  confirmRsvp (data) {
    return $axios.post(`/api/guests/rsvp/`,
    {
      data
    },
    {
      headers: {
        'Authorization': `Bearer ${$config.apiToken}`
      }
    })
  },

  getGuests (params){
    const query = qs.stringify({
      populate: '*',
      filters: {
        invitationId: {
          $eq:  params.invitationId === '' ? [] : params.invitationId
        },
        group: {
          $in:  params.group === '' ? [] : params.group
        },
        name: {
          $contains: params.searchName === '' ? [] : params.searchName
        }
      },
      pagination: {
        page: params.pagination.page === '' ? [] : params.pagination.page,
        pageSize: params.pagination.pageSize === '' ? [] : params.pagination.pageSize
      },
      sort: ['createdAt:desc']
    }, { encodeValuesOnly: true});

    return $axios.get(`/api/guests?${query}`)
  },

  createGuest(params) {
    return $axios.post(`/api/guests`, params )
  },

  editGuest(id, params){
    return $axios.put(`/api/guests/${id}`, params)
  },

  deleteGuest(id){
    return $axios.delete(`/api/guests/${id}`)
  }
})
