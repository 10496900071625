const qs = require('qs');
export default ($config, $axios) => ({
  getThemes (params) {

    // const sort = params.date ? 'createdAt' : 'rating'
    // const hasFilter = params.category ? `&filters[invitation_category][name][$eq]=${params.category}`: ''
    // return $axios.get(`/api/themes?populate=%2A${hasFilter}&${sort}%3Adesc`)
    const query = qs.stringify({
      populate: '*',
      filters: {
        invitation_category: {
          name: {
            $in:  params.category === '' ? [] : params.category
          }
        },
      },
      pagination: {
        pageSize: params.pageSize,
        page: params.page,
      },
      sort: ['createdAt:desc']
    }, { encodeValuesOnly: true});

    return $axios.get(`/api/themes?${query}`)
  },

  getTheme (slug) {
    return $axios.get(`/api/themes/${slug}`)
  }
})
