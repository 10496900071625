export default ({app}, inject) => {
  inject('formatPrice', function (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  })

  inject('convertDate', (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }

    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
    const day = new Date(dateString).getDay()
    const newDate = new Date(dateString).toLocaleDateString('id-ID', options)
    return days[day] + ', ' + newDate + ' - ' + ('0' + new Date(dateString).getHours()).slice(-2) + ':' + ('0' + new Date(dateString).getMinutes()).slice(-2) + ' WIB'
  })

  inject('formatDateRead', (date, format = [false, true], monthOnly = false) => {
      const d = new Date(date);
      const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
      const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum\'at', 'Sabtu'];
      const month = d.getMonth();
      const day = d.getDate();
      const year = d.getFullYear();
      if (!monthOnly) {
        return `${format[0] ? days[day]: day} ${format[1] ? months[month]: month} ${year}`;
      }

      return `${format[1] ? months[month]: month} ${year}`;
  })

  inject('formatTime', (date) => {
    const d = new Date(date);
    const hours = d.getHours();
    const minutes = d.getMinutes();
    return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`;
  })

  inject('encodeQueryData', (data) => {
    const ret = [];

    for (const d in data) {
        let value = data[d] == null ? '' : data[d]

        if (typeof value === "object") {
            value = data[d].join(",");
        }

        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(value));
    }

    return ret.join("&");
  })

  inject('createTitleInvitation', (themeData) => {
    const groomName = themeData.groomNickName
    const brideName = themeData.brideNickname
    const isReverse = themeData.isReverseCpSection

    if (isReverse) {
      return [brideName, groomName]
    } else {
      return [groomName, brideName]
    }
  })


  inject('getImageFormat', (attrImage, isThumbnail) => {

    // let formatFor = ['large', 'medium', 'small', 'thumbnail']
    const breakPoint = {
      'sm': 640,
      'md': 768,
      'lg': 1024,
      'xl': 1280,
      '2xl': 1536,
    }
    let currentScreen = 'sm'
    let biggestScreen = 0

    for (const breakPointName of Object.keys(breakPoint)) {
      const breakPoinValue = breakPoint[breakPointName]
      if (breakPoinValue > biggestScreen && window.innerWidth >= breakPoinValue) {
        biggestScreen = breakPoinValue
        currentScreen = breakPointName
      }
    }

    const useFormat = {
        'sm': 'large',
        'md': 'large',
        'lg': 'large',
        'xl': 'large',
        '2xl': 'large',
    }


    const currentFormat = useFormat[currentScreen]

    if (!attrImage) return ''
    const imageUrl = attrImage.formats[currentFormat].url

    if (!isThumbnail) {
      if (imageUrl) return imageUrl
      return attrImage.url
    }else {
      return attrImage.formats.small.url
    }
  })


}
