export default ($config, $axios) => ({
    getUserGuides () {
      return $axios.get(`/api/user-guides?populate=%2A`)
    },

    getBanners () {
      return $axios.get(`/api/banners?populate=%2A`)
    },

    getPopUpBanners () {
      return $axios.get(`/api/popup-ads?populate=%2A`)
    }
  })
