export const state = () => ({
  themes:{
    color: null,
    textColorPrimary: null,
    textColorSecondary: null,
  }
})


export const mutations = {
  setTheme(state, themes){
    console.log(themes)
    state.themes = themes
  }
}
