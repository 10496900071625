export const state = () => ({
    guestData: {
        name :'',
        phoneNumber: '',
        invitationId: '',
        group: ''
    },
    selectedGuest: '',
    showDialogGuest: false,
    showDialogImport: false,
})

export const mutations = {
  setGuestData(state, guest) {
    state.guestData = guest
  },
  setShowDialogGuest(state, value) {
    state.showDialogGuest = value
  },
  setShowDialogImport(state, value) {
    state.showDialogImport = value
  },
  setSelectedGuest(state, value){
    state.selectedGuest = value
  }
}

export const getters = {
    getGuestData: state => state.guestData,
    selectedGuest: state => state.selectedGuest
}