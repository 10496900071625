export default ($config, $axios) => ({
  register (params) {
    return $axios.post(`/api/auth/local/register`, params )
  },
  getGoogle() {
    return $axios.get('/api/connect/google')
  },
  forgotPassword (email) {
    return $axios.post(`/api/auth/forgot-password`, { email })
  },

  // withGoogle({ code }) {
  //   return $axios.get('/connect/google/callback?code=' + code)
  // },

  withGoogle({ code }) {
    return $axios.get('/api/auth/google/callback?access_token=' + code)
  },
  profile() {
    return $axios.get('/api/users/me')
  },

})
