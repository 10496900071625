import moment from 'moment'

export const state = () => ({
    // Create or Update Invitation Param
    createInvitationParam: {
        package: '',
        theme: '',
        brideNickname: '',
        bride: '',
        brideFather: '',
        brideMother: '',
        brideInstagram: '',
        groom: '',
        groomNickname: '',
        groomFather: '',
        groomMother: '',
        groomInstagram: '',
        invitationId: '',
        events: [],
        loveStorys: [],
        digitalGifts: [],
        music: 1,
        videoPreweedingUrl: '',
        title: ''
    },

    invitationId: '',

    // Data Calon Mempelai
    brideData: {
      brideNickname: '',
      bride: '',
      brideFather: '',
      brideMother: '',
      brideInstagram: '',
      groom: '',
      groomNickname: '',
      groomFather: '',
      groomMother: '',
      groomInstagram: '',
    },

    bridePhotoId: '',
    groomPhotoId: '',
    bridePhoto: '',
    groomPhoto: '',

    // Theme
    theme: 1,

    // data package yang dipilih
    packageData: '',
    package: '',

    //  data events
    eventData: [],

    // data love story
    loveStoryData: [],

    // digital gift
    digitalGiftData: [],

    // data music
    musicData: 1,

    title: '',



    // Step Calon Mempelai
    // bridePhoto: '/icon/image-place-holder.svg',
    // bridePhotoId: '',
    // groomPhoto: '/icon/image-place-holder.svg',
    // groomPhotoId: '',

    // Step Acara
    showDialogEvent: false,
    indexEvent: -1,

    // Step CeritaCinta
    showDialogLoveStory: false,
    indexLoveStory: -1,

    // Step Kado Digital
    showDialogDigitalGift: false,
    indexDigitalGift: -1,

    // Step Foto
    coverPhoto: '',
    coverPhotoId: 0,
    galleryPhotos: [],
    videoPreweedingUrl: '',

    // Flagging
    themeCategory: '',
    eventQuota: 0,
    galleryQuota: 0,
    loveStoryQuota: 0,
    packages: [],
    totalInvitation: [],
})

export const mutations = {
    setCreateInvitationParam(state, value) {
        if (value.package && value.package.data) state.createInvitationParam.package = value.package.data.id
        if (value.theme && value.theme.data) state.createInvitationParam.theme = value.theme.data.id
        state.createInvitationParam.brideNickname = value.brideNickname ? value.brideNickname : ''
        state.createInvitationParam.bride = value.bride ? value.bride : ''
        state.createInvitationParam.brideFather = value.brideFather ? value.brideFather : ''
        state.createInvitationParam.brideMother = value.brideMother ? value.brideMother : ''
        state.createInvitationParam.brideInstagram = value.brideInstagram ? value.brideInstagram : ''
        state.createInvitationParam.groomNickname = value.groomNickname ? value.groomNickname : ''
        state.createInvitationParam.groom = value.groom ? value.groom : ''
        state.createInvitationParam.groomFather = value.groomFather ? value.groomFather : ''
        state.createInvitationParam.groomMother = value.groomMother ? value.groomMother : ''
        state.createInvitationParam.groomInstagram = value.groomInstagram ? value.groomInstagram : ''
        state.createInvitationParam.videoPreweedingUrl = value.videoPreweedingUrl ? value.videoPreweedingUrl : ''
        if (value.music && value.music.data) state.createInvitationParam.music = value.music.data.id
        state.createInvitationParam.title = value.title ? value.title : ''

        if (value.groomPhoto && value.groomPhoto.data) {
            state.groomPhotoId = value.groomPhoto.data.id
            state.groomPhoto = value.groomPhoto.data.attributes.url
        }
        else {
            state.groomPhoto = '/icon/image-place-holder.svg'
        }

        if (value.bridePhoto && value.bridePhoto.data) {
            state.bridePhotoId = value.bridePhoto.data.id
            state.bridePhoto = value.bridePhoto.data.attributes.url
        }
        else {
            state.bridePhoto = '/icon/image-place-holder.svg'
        }

        if (value.cover && value.cover.data) {
            state.coverPhotoId = value.cover.data.id
            state.coverPhoto = value.cover.data.attributes.url
        }
        else {
            state.coverPhoto = ''
        }

        if (value.photos && value.photos.data) {
            state.galleryPhotos = []
            value.photos.data.forEach((value, index) => {
                console.log(value)
                state.galleryPhotos.push({
                    id: value.id,
                    src: value.attributes.url
                });
            });
        }

        if (value.events && value.events.length !== 0) {
            state.createInvitationParam.events = []
            value.events.forEach((value, index) => {
                state.createInvitationParam.events.push({
                    name: value.name,
                    place: value.place,
                    description: value.description,
                    location: value.location,
                    timeStart: value.timeStart,
                    timeEnd: value.timeEnd,

                });
            });
        } else {
            state.createInvitationParam.events = [{
                name: 'Akad Nikah',
                place: '',
                description: '',
                location: '',
                timeStart: moment().add(1, 'M').format("YYYY-MM-DDThh:mm:ssZ"),
                timeEnd: moment().add(1, 'M').format("YYYY-MM-DDThh:mm:ssZ"),
            }]
        }

        if (value.loveStorys) {
            state.createInvitationParam.loveStorys = []
            value.loveStorys.forEach((value, index) => {
                state.createInvitationParam.loveStorys.push({
                    title: value.title,
                    story: value.story,
                    timeText: value.timeText,
                });
            });
        }

        if (value.digitalGifts) {
            state.createInvitationParam.digitalGifts = []
            value.digitalGifts.forEach((value, index) => {
                state.createInvitationParam.digitalGifts.push({
                    name: value.name,
                    onBehalfOf: value.onBehalfOf,
                    accountNumber: value.accountNumber,
                });
            });
        }

        state.themeCategory = (value.theme && value.theme.data) ? value.theme.data.attributes.invitation_category.data.attributes.name : ''
        state.eventQuota = (value.package && value.package.data) ? value.package.data.attributes.eventQuota : 0
        state.galleryQuota = (value.package && value.package.data) ? value.package.data.attributes.galleryQuota : 0
        state.loveStoryQuota = (value.package && value.package.data) ? value.package.data.attributes.loveStoryQuota : 0
        if (state.themeCategory === "Syar'i"){
            state.bridePhoto = '/icon/avatar_akhwat.svg'
            state.groomPhoto = '/icon/avatar_ikhwan.svg'
        } else {
            state.bridePhoto = '/icon/image-place-holder.svg'
            state.groomPhoto = '/icon/image-place-holder.svg'
        }
    },

    setPackages(state, value) {
        const packages = value.map(pkg => {
          return {
            id: pkg.id,
            eventQuota: pkg.attributes.eventQuota,
            loveStoryQuota: pkg.attributes.loveStoryQuota,
            galleryQuota: pkg.attributes.galleryQuota,
            guestQuota: pkg.attributes.guestQuota,
          }
        })
        state.packages = packages
    },

    // Step Paket
    setPackage(state, value) {
      state.package = value ? parseInt(value) : null
    },


    // Step Calon Mempelai
    setBride(state, value) {
        state.createInvitationParam.bride = value
    },
    setbrideNickname(state, value) {
        state.createInvitationParam.brideNickname = value
    },
    setBrideFather(state, value) {
        state.createInvitationParam.brideFather = value
    },
    setBrideMother(state, value) {
        state.createInvitationParam.brideMother = value
    },
    setBrideInstagram(state, value) {
        state.createInvitationParam.brideInstagram = value
    },
    setGroom(state, value) {
        state.createInvitationParam.groom = value
    },
    setgroomNickname(state, value) {
        state.createInvitationParam.groomNickname = value
    },
    setGroomFather(state, value) {
        state.createInvitationParam.groomFather = value
    },
    setGroomMother(state, value) {
        state.createInvitationParam.groomMother = value
    },
    setGroomInstagram(state, value) {
        state.createInvitationParam.groomInstagram = value
    },
    setInvitationId(state, value) {
        state.invitationId = value
    },
    setGroomPhotoId(state, value) {
        state.groomPhotoId = value
    },
    setBridePhotoId(state, value) {
        state.bridePhotoId = value
    },
    // setBridePhoto(state, value) {
    //     state.bridePhoto = value
    // },
    // setGroomPhoto(state, value) {
    //     state.groomPhoto = value
    // },

    // Step Acara
    setShowDialogEvent(state, value) {
        state.showDialogEvent = value
    },
    addEvent(state, value) {
        state.createInvitationParam.events.push(value)
    },
    updateEvent(state, { index, data }) {
        state.createInvitationParam.events.splice(index, 1, data)
    },
    deleteEvent(state, value) {
        if (value === 0)
            return

        state.createInvitationParam.events.splice(value, 1)
    },
    setIndexEvent(state, value) {
        state.indexEvent = value
    },

    // Step Cerita Cinta
    setShowDialogLoveStory(state, value) {
        state.showDialogLoveStory = value
    },
    addLoveStory(state, value) {
        console.log(value)
        state.createInvitationParam.loveStorys.push(value)
    },
    updateLoveStory(state, { index, data }) {
        state.createInvitationParam.loveStorys.splice(index, 1, data)
    },
    deleteLoveStory(state, value) {
        if (value === 0)
            return

        state.createInvitationParam.loveStorys.splice(value, 1)
    },
    setIndexLoveStory(state, value) {
        state.indexLoveStory = value
    },

    // Step Kado Digital
    setShowDialogDigitalGift(state, value) {
        state.showDialogDigitalGift = value
    },
    addDigitalGift(state, value) {
        state.createInvitationParam.digitalGifts.push(value)
    },
    updateDigitalGift(state, { index, data }) {
        state.createInvitationParam.digitalGifts.splice(index, 1, data)
    },
    deleteDigitalGift(state, value) {
        if (value === 0)
            return

        state.createInvitationParam.digitalGifts.splice(value, 1)
    },
    setIndexDigitalGift(state, value) {
        state.indexDigitalGift = value
    },

    // Step Musik
    setMusic(state, value) {
      state.createInvitationParam.music = value
    },



    // Flagging
    setThemeCategory(state, value) {
        state.themeCategory = value
        // if (state.themeCategory === "Syar'i"){
        //     state.bridePhoto = '/icon/avatar_akhwat.svg'
        //     state.groomPhoto = '/icon/avatar_ikhwan.svg'
        // } else {
        //     state.bridePhoto = '/icon/image-place-holder.svg'
        //     state.groomPhoto = '/icon/image-place-holder.svg'
        // }
    },
    setEventQuota(state, value) {
        state.eventQuota = value
    },
    setLoveStoryQuota(state, value) {
        state.loveStoryQuota = value
    },
    setGalleryQuota(state, value) {
        state.galleryQuota = value
    },

    //= =================================================
    setTheme(state, value) {
      console.log(value)
      state.theme = value
    },
    setPackageData(state, value) {
      state.packageData = value
    },
    setBrideData(state, value) {
      state.brideData = {...value}
    },

    setBridePhoto(state, value) {
      state.bridePhoto = value.url
      state.bridePhotoId = value.id
    },

    setGroomPhoto(state, value) {
      state.groomPhoto = value.url
      state.groomPhotoId = value.id
    },

    setEventData(state, event) {
      state.eventData = event
    },
    addEventData(state, event) {
        console.log(event);
      state.eventData.push(event)
    },
    editEventData(state, event) {
      state.eventData.splice(event.index, 1, event.data)
    },
    deleteEventData(state, idx) {
      state.eventData.splice(idx, 1)
    },
    setLoveStoryData(state, loveStory) {
      state.loveStoryData = loveStory
    },
    addLoveStoryData(state, event) {
      state.loveStoryData.push(event)
    },
    editLoveStoryData(state, story) {
      state.loveStoryData.splice(story.index, 1, story.data)
    },
    deleteLoveStoryData(state, idx) {
      state.loveStoryData.splice(idx, 1)
    },
    setDigitalGiftData(state, digitalGift) {
      state.digitalGiftData = digitalGift
    },
    addDigitalGiftData(state, value) {
      state.digitalGiftData.push(value)
    },
    editDigitalGiftData(state, gift) {
      state.digitalGiftData.splice(gift.index, 1, gift.data)
    },
    deleteDigitalGiftData(state, idx) {
      state.digitalGiftData.splice(idx, 1)
    },

    setMusicData(state, music) {
      state.musicData = music
    },

    // Step Foto
    setCoverPhotoId(state, value) {
      state.coverPhotoId = value
    },
    setCoverPhoto(state, value) {
        state.coverPhoto = value
    },
    setGalleryPhotos(state, value) {
      state.galleryPhotos.push(value)
    },
    deleteGalleryPhoto(state, value) {
        state.galleryPhotos.splice(value, 1)
    },
    setVideoPreweedingUrl(state, value) {
      state.videoPreweedingUrl = value
    },

    // Step Link
    setTitle(state, value) {
        state.title = value
    },

    setTotalInvitation(state, value) {
      state.totalInvitation = value
    }
}

export const actions = {
  setPackageInfo({ commit, state }, value) {
      commit('setPackages', value.items)
      if (value.selected !== undefined) {
        commit('setPackageData', state.packages.find(item => item.id === value.selected))
      }
  }
}

export const getters = {
    packages : state => state.packages,
    invitationId: state => state.invitationId,
    packageInfo: state => state.packageData,
    eventInfo: state => state.eventData,
    loveStorysInfo: state => state.loveStoryData,
    digitalGiftInfo: state => state.digitalGiftData,
    musicInfo: state => state.musicData,
    mediaInfo: state => {
      return {
        coverPhotoId: state.coverPhotoId,
        coverPhoto: state.coverPhoto,
        galleryPhotos: state.galleryPhotos,
        videoPreweedingUrl: state.videoPreweedingUrl
      }
    },

    // Step Foto
    getPhoto: state => {
      return {
        coverPhotoId: state.coverPhotoId,
        coverPhoto: state.coverPhoto,
        galleryPhotos: state.galleryPhotos,
        bridePhoto: state.bridePhoto,
        groomPhoto: state.groomPhoto,
        bridePhotoId: state.bridePhotoId,
        groomPhotoId: state.groomPhotoId,
      }
    },

    invitationParams: state => {
      return {
        ...state.brideData,
        invitationId: state.invitationId,
        loveStorys: state.loveStoryData,
        music: state.musicData,
        digitalGifts: state.digitalGiftData,
        package: state.package,
        theme: state.theme,
        events: state.eventData,
        videoPreweedingUrl: state.videoPreweedingUrl,
        title: state.title ? state.title : null,
      }
    },


}
