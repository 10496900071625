// import Tawk from 'vue-tawk'

// Vue.use(Tawk, {
//   tawkSrc: 'https://embed.tawk.to/620ce647a34c245641268fba/1fs1672ng'
// })

import Vue from 'vue';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue';

export default function () {
    Vue.use(TawkMessengerVue, {
        propertyId : '620ce647a34c245641268fba',
        widgetId : '1fs1672ng'
    });
}
