//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TheHeader',
      props: {
      isTransparent: {
        default: true,
        type: Boolean
      },
    },
    data () {
      return {
        isTransParrent: this.isTransparent,
        isShowMobileNav: false,
      }
    },
    mounted () {
      window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
      onScroll () {
          if (!this.isTransparent)
           return

          const currentScrollPosition = document.documentElement.scrollTop
          // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
          if (currentScrollPosition < 0) {
            return
          }

          if (currentScrollPosition > 80) {
            this.isTransParrent = false
          } else {
            this.isTransParrent = true
          }
      },
      openPage (page) {
        this.$router.push(page)
        this.isShowMobileNav = false
      },
      logout () {
        this.$auth.logout()
        this.isShowMobileNav = false
      },
      goTo(refName) {
        if (this.$route.name !== 'index') {
          this.$router.push('/')
          setTimeout(() => {
            document.getElementById(refName).scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }, 300)
        }else {
          document.getElementById(refName).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }


        if (this.isShowMobileNav) {
          this.isShowMobileNav = false
        }

      }
    }
}
