//
//
//
//
//

export default {
    props: {
        id: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'normal'
        },
        large: {
          type: Boolean,
          default: false
        },
        type: {
            type: String,
            default: 'button'
        },
        px: {
            type: String,
            default: 'px-10'
        },
        py: {
            type: String,
            default: 'py-2'
        },
        rounded: {
            type: String,
            default: 'rounded-md'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        h: {
            type: String,
            default: 'h-10'
        }
    },
    computed: {
        height () {
          let size = ''
          if (this.large) {
            size = 'h-[60px]'
          } else {
            size = this.h
          }

          return size
        }
    },
    methods: {
      onClick() {
        if (!this.disabled) {
          this.$emit('click')
        }
      }
    }
}
