//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "loggedInUser"])
  },
  methods: {
    logout() {
      this.$auth.logout()
      this.$router.push('/')
      window.location.reload()
    }
  }
}
