export const AboutUsCard = () => import('../../components/AboutUsCard.vue' /* webpackChunkName: "components/about-us-card" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AppBanner = () => import('../../components/AppBanner.vue' /* webpackChunkName: "components/app-banner" */).then(c => wrapFunctional(c.default || c))
export const AppPopup = () => import('../../components/AppPopup.vue' /* webpackChunkName: "components/app-popup" */).then(c => wrapFunctional(c.default || c))
export const BannerHome = () => import('../../components/BannerHome.vue' /* webpackChunkName: "components/banner-home" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const DigitalGiftCard = () => import('../../components/DigitalGiftCard.vue' /* webpackChunkName: "components/digital-gift-card" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const FilterInvitationHorizontal = () => import('../../components/FilterInvitationHorizontal.vue' /* webpackChunkName: "components/filter-invitation-horizontal" */).then(c => wrapFunctional(c.default || c))
export const FilterPrice = () => import('../../components/FilterPrice.vue' /* webpackChunkName: "components/filter-price" */).then(c => wrapFunctional(c.default || c))
export const IconDecoration = () => import('../../components/IconDecoration.vue' /* webpackChunkName: "components/icon-decoration" */).then(c => wrapFunctional(c.default || c))
export const ImageCard = () => import('../../components/ImageCard.vue' /* webpackChunkName: "components/image-card" */).then(c => wrapFunctional(c.default || c))
export const LoveStoryCard = () => import('../../components/LoveStoryCard.vue' /* webpackChunkName: "components/love-story-card" */).then(c => wrapFunctional(c.default || c))
export const MusicCard = () => import('../../components/MusicCard.vue' /* webpackChunkName: "components/music-card" */).then(c => wrapFunctional(c.default || c))
export const MusicPlayer = () => import('../../components/MusicPlayer.vue' /* webpackChunkName: "components/music-player" */).then(c => wrapFunctional(c.default || c))
export const NgondangIdLogo = () => import('../../components/NgondangIdLogo.vue' /* webpackChunkName: "components/ngondang-id-logo" */).then(c => wrapFunctional(c.default || c))
export const PackageCard = () => import('../../components/PackageCard.vue' /* webpackChunkName: "components/package-card" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PlansCard = () => import('../../components/PlansCard.vue' /* webpackChunkName: "components/plans-card" */).then(c => wrapFunctional(c.default || c))
export const SocialHead = () => import('../../components/SocialHead.vue' /* webpackChunkName: "components/social-head" */).then(c => wrapFunctional(c.default || c))
export const StatusAuth = () => import('../../components/StatusAuth.vue' /* webpackChunkName: "components/status-auth" */).then(c => wrapFunctional(c.default || c))
export const SvgFilterImage = () => import('../../components/SvgFilterImage.vue' /* webpackChunkName: "components/svg-filter-image" */).then(c => wrapFunctional(c.default || c))
export const TheButton = () => import('../../components/TheButton.vue' /* webpackChunkName: "components/the-button" */).then(c => wrapFunctional(c.default || c))
export const TheFilter = () => import('../../components/TheFilter.vue' /* webpackChunkName: "components/the-filter" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const ThemeCard = () => import('../../components/ThemeCard.vue' /* webpackChunkName: "components/theme-card" */).then(c => wrapFunctional(c.default || c))
export const ThemeList = () => import('../../components/ThemeList.vue' /* webpackChunkName: "components/theme-list" */).then(c => wrapFunctional(c.default || c))
export const ToggleButton = () => import('../../components/ToggleButton.vue' /* webpackChunkName: "components/toggle-button" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const UseTemplateButton = () => import('../../components/UseTemplateButton.vue' /* webpackChunkName: "components/use-template-button" */).then(c => wrapFunctional(c.default || c))
export const WhyUsList = () => import('../../components/WhyUsList.vue' /* webpackChunkName: "components/why-us-list" */).then(c => wrapFunctional(c.default || c))
export const FloatNavigation = () => import('../../components/floatNavigation.vue' /* webpackChunkName: "components/float-navigation" */).then(c => wrapFunctional(c.default || c))
export const PulseLoading = () => import('../../components/pulseLoading.vue' /* webpackChunkName: "components/pulse-loading" */).then(c => wrapFunctional(c.default || c))
export const CardCurrentInvitation = () => import('../../components/Card/CurrentInvitation.vue' /* webpackChunkName: "components/card-current-invitation" */).then(c => wrapFunctional(c.default || c))
export const CardDashboardAction = () => import('../../components/Card/DashboardAction.vue' /* webpackChunkName: "components/card-dashboard-action" */).then(c => wrapFunctional(c.default || c))
export const CardInvitationItem = () => import('../../components/Card/InvitationItem.vue' /* webpackChunkName: "components/card-invitation-item" */).then(c => wrapFunctional(c.default || c))
export const CardInvitationReport = () => import('../../components/Card/InvitationReport.vue' /* webpackChunkName: "components/card-invitation-report" */).then(c => wrapFunctional(c.default || c))
export const CardPackageCard = () => import('../../components/Card/PackageCard.vue' /* webpackChunkName: "components/card-package-card" */).then(c => wrapFunctional(c.default || c))
export const CardPortofolio = () => import('../../components/Card/Portofolio.vue' /* webpackChunkName: "components/card-portofolio" */).then(c => wrapFunctional(c.default || c))
export const CardTransactionItem = () => import('../../components/Card/TransactionItem.vue' /* webpackChunkName: "components/card-transaction-item" */).then(c => wrapFunctional(c.default || c))
export const CardUserGuide = () => import('../../components/Card/UserGuide.vue' /* webpackChunkName: "components/card-user-guide" */).then(c => wrapFunctional(c.default || c))
export const FormsCheckBox = () => import('../../components/Forms/CheckBox.vue' /* webpackChunkName: "components/forms-check-box" */).then(c => wrapFunctional(c.default || c))
export const FormsDropDown = () => import('../../components/Forms/DropDown.vue' /* webpackChunkName: "components/forms-drop-down" */).then(c => wrapFunctional(c.default || c))
export const FormsInputDate = () => import('../../components/Forms/InputDate.vue' /* webpackChunkName: "components/forms-input-date" */).then(c => wrapFunctional(c.default || c))
export const FormsInputEmbedMaps = () => import('../../components/Forms/InputEmbedMaps.vue' /* webpackChunkName: "components/forms-input-embed-maps" */).then(c => wrapFunctional(c.default || c))
export const FormsInputPassword = () => import('../../components/Forms/InputPassword.vue' /* webpackChunkName: "components/forms-input-password" */).then(c => wrapFunctional(c.default || c))
export const FormsInputPhone = () => import('../../components/Forms/InputPhone.vue' /* webpackChunkName: "components/forms-input-phone" */).then(c => wrapFunctional(c.default || c))
export const FormsInputSelect = () => import('../../components/Forms/InputSelect.vue' /* webpackChunkName: "components/forms-input-select" */).then(c => wrapFunctional(c.default || c))
export const FormsTextArea = () => import('../../components/Forms/TextArea.vue' /* webpackChunkName: "components/forms-text-area" */).then(c => wrapFunctional(c.default || c))
export const FormsTextField = () => import('../../components/Forms/TextField.vue' /* webpackChunkName: "components/forms-text-field" */).then(c => wrapFunctional(c.default || c))
export const FormsTextFieldIcon = () => import('../../components/Forms/TextFieldIcon.vue' /* webpackChunkName: "components/forms-text-field-icon" */).then(c => wrapFunctional(c.default || c))
export const ModalAddUrlDialog = () => import('../../components/Modal/AddUrlDialog.vue' /* webpackChunkName: "components/modal-add-url-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalBasic = () => import('../../components/Modal/Basic.vue' /* webpackChunkName: "components/modal-basic" */).then(c => wrapFunctional(c.default || c))
export const ModalDialog = () => import('../../components/Modal/Dialog.vue' /* webpackChunkName: "components/modal-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalDigitalGift = () => import('../../components/Modal/DigitalGift.vue' /* webpackChunkName: "components/modal-digital-gift" */).then(c => wrapFunctional(c.default || c))
export const ModalEvent = () => import('../../components/Modal/Event.vue' /* webpackChunkName: "components/modal-event" */).then(c => wrapFunctional(c.default || c))
export const ModalGalery = () => import('../../components/Modal/Galery.vue' /* webpackChunkName: "components/modal-galery" */).then(c => wrapFunctional(c.default || c))
export const ModalGuestForm = () => import('../../components/Modal/GuestForm.vue' /* webpackChunkName: "components/modal-guest-form" */).then(c => wrapFunctional(c.default || c))
export const ModalImport = () => import('../../components/Modal/Import.vue' /* webpackChunkName: "components/modal-import" */).then(c => wrapFunctional(c.default || c))
export const ModalLocationDialog = () => import('../../components/Modal/LocationDialog.vue' /* webpackChunkName: "components/modal-location-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalLoveStory = () => import('../../components/Modal/LoveStory.vue' /* webpackChunkName: "components/modal-love-story" */).then(c => wrapFunctional(c.default || c))
export const LoadingPackage = () => import('../../components/Loading/Package.vue' /* webpackChunkName: "components/loading-package" */).then(c => wrapFunctional(c.default || c))
export const LoadingTheme = () => import('../../components/Loading/Theme.vue' /* webpackChunkName: "components/loading-theme" */).then(c => wrapFunctional(c.default || c))
export const NavigationItem = () => import('../../components/Navigation/NavigationItem.vue' /* webpackChunkName: "components/navigation-item" */).then(c => wrapFunctional(c.default || c))
export const NavigationSideNavigation = () => import('../../components/Navigation/SideNavigation.vue' /* webpackChunkName: "components/navigation-side-navigation" */).then(c => wrapFunctional(c.default || c))
export const SectionsHero = () => import('../../components/Sections/Hero.vue' /* webpackChunkName: "components/sections-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurPartners = () => import('../../components/Sections/OurPartners.vue' /* webpackChunkName: "components/sections-our-partners" */).then(c => wrapFunctional(c.default || c))
export const SectionsOurTeam = () => import('../../components/Sections/OurTeam.vue' /* webpackChunkName: "components/sections-our-team" */).then(c => wrapFunctional(c.default || c))
export const SectionsPlans = () => import('../../components/Sections/Plans.vue' /* webpackChunkName: "components/sections-plans" */).then(c => wrapFunctional(c.default || c))
export const SectionsPrivacyPolicy = () => import('../../components/Sections/PrivacyPolicy.vue' /* webpackChunkName: "components/sections-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const SectionsTermsAndCondition = () => import('../../components/Sections/TermsAndCondition.vue' /* webpackChunkName: "components/sections-terms-and-condition" */).then(c => wrapFunctional(c.default || c))
export const SectionsThemePick = () => import('../../components/Sections/ThemePick.vue' /* webpackChunkName: "components/sections-theme-pick" */).then(c => wrapFunctional(c.default || c))
export const SectionsWhyUs = () => import('../../components/Sections/WhyUs.vue' /* webpackChunkName: "components/sections-why-us" */).then(c => wrapFunctional(c.default || c))
export const StepsEight = () => import('../../components/Steps/Eight.vue' /* webpackChunkName: "components/steps-eight" */).then(c => wrapFunctional(c.default || c))
export const StepsFive = () => import('../../components/Steps/Five.vue' /* webpackChunkName: "components/steps-five" */).then(c => wrapFunctional(c.default || c))
export const StepsFour = () => import('../../components/Steps/Four.vue' /* webpackChunkName: "components/steps-four" */).then(c => wrapFunctional(c.default || c))
export const StepsOne = () => import('../../components/Steps/One.vue' /* webpackChunkName: "components/steps-one" */).then(c => wrapFunctional(c.default || c))
export const StepsSeven = () => import('../../components/Steps/Seven.vue' /* webpackChunkName: "components/steps-seven" */).then(c => wrapFunctional(c.default || c))
export const StepsSix = () => import('../../components/Steps/Six.vue' /* webpackChunkName: "components/steps-six" */).then(c => wrapFunctional(c.default || c))
export const StepsThree = () => import('../../components/Steps/Three.vue' /* webpackChunkName: "components/steps-three" */).then(c => wrapFunctional(c.default || c))
export const StepsTwo = () => import('../../components/Steps/Two.vue' /* webpackChunkName: "components/steps-two" */).then(c => wrapFunctional(c.default || c))
export const TemplatesInvitationList = () => import('../../components/templates/InvitationList.vue' /* webpackChunkName: "components/templates-invitation-list" */).then(c => wrapFunctional(c.default || c))
export const TemplatesPaymentHistoryList = () => import('../../components/templates/PaymentHistoryList.vue' /* webpackChunkName: "components/templates-payment-history-list" */).then(c => wrapFunctional(c.default || c))
export const UtilsFab = () => import('../../components/utils/Fab.vue' /* webpackChunkName: "components/utils-fab" */).then(c => wrapFunctional(c.default || c))
export const UtilsFloatingWhatsapp = () => import('../../components/utils/floatingWhatsapp.vue' /* webpackChunkName: "components/utils-floating-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernColorTheme = () => import('../../components/templates/modern/ColorTheme.vue' /* webpackChunkName: "components/templates-modern-color-theme" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernCover = () => import('../../components/templates/modern/Cover.vue' /* webpackChunkName: "components/templates-modern-cover" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernFooterTheme = () => import('../../components/templates/modern/FooterTheme.vue' /* webpackChunkName: "components/templates-modern-footer-theme" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernVideoSection = () => import('../../components/templates/modern/VideoSection.vue' /* webpackChunkName: "components/templates-modern-video-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernConfirmationSectionCardConfirmation = () => import('../../components/templates/modern/ConfirmationSection/CardConfirmation.vue' /* webpackChunkName: "components/templates-modern-confirmation-section-card-confirmation" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernConfirmationSectionConfirmAttendanceDialog = () => import('../../components/templates/modern/ConfirmationSection/ConfirmAttendanceDialog.vue' /* webpackChunkName: "components/templates-modern-confirmation-section-confirm-attendance-dialog" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernConfirmationSectionQrCode = () => import('../../components/templates/modern/ConfirmationSection/QrCode.vue' /* webpackChunkName: "components/templates-modern-confirmation-section-qr-code" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernConfirmationSection = () => import('../../components/templates/modern/ConfirmationSection/index.vue' /* webpackChunkName: "components/templates-modern-confirmation-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernCountdownSection = () => import('../../components/templates/modern/CountdownSection/index.vue' /* webpackChunkName: "components/templates-modern-countdown-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernCpInfoSection = () => import('../../components/templates/modern/CpInfoSection/index.vue' /* webpackChunkName: "components/templates-modern-cp-info-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernDigitalGiftSectionCardGiftDigital = () => import('../../components/templates/modern/DigitalGiftSection/CardGiftDigital.vue' /* webpackChunkName: "components/templates-modern-digital-gift-section-card-gift-digital" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernDigitalGiftSection = () => import('../../components/templates/modern/DigitalGiftSection/index.vue' /* webpackChunkName: "components/templates-modern-digital-gift-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernEventsSectionEventCard = () => import('../../components/templates/modern/EventsSection/EventCard.vue' /* webpackChunkName: "components/templates-modern-events-section-event-card" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernEventsSection = () => import('../../components/templates/modern/EventsSection/index.vue' /* webpackChunkName: "components/templates-modern-events-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernGaleryCard = () => import('../../components/templates/modern/GalerySection/GaleryCard.vue' /* webpackChunkName: "components/templates-modern-galery-card" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernGalerySlider = () => import('../../components/templates/modern/GalerySection/GalerySlider.vue' /* webpackChunkName: "components/templates-modern-galery-slider" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernGalerySectionGridGalery = () => import('../../components/templates/modern/GalerySection/GridGalery.vue' /* webpackChunkName: "components/templates-modern-galery-section-grid-galery" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernGalerySection = () => import('../../components/templates/modern/GalerySection/index.vue' /* webpackChunkName: "components/templates-modern-galery-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernGreetingScroll = () => import('../../components/templates/modern/GreetingSection/GreetingScroll.vue' /* webpackChunkName: "components/templates-modern-greeting-scroll" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernGreetingSlider = () => import('../../components/templates/modern/GreetingSection/GreetingSlider.vue' /* webpackChunkName: "components/templates-modern-greeting-slider" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernGreetingSection = () => import('../../components/templates/modern/GreetingSection/index.vue' /* webpackChunkName: "components/templates-modern-greeting-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernMoreInfoSectionHealthProtocolSection = () => import('../../components/templates/modern/MoreInfoSection/HealthProtocolSection.vue' /* webpackChunkName: "components/templates-modern-more-info-section-health-protocol-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernMoreInfoCard = () => import('../../components/templates/modern/MoreInfoSection/InfoCard.vue' /* webpackChunkName: "components/templates-modern-more-info-card" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernMoreInfoSectionLiveStreamingSection = () => import('../../components/templates/modern/MoreInfoSection/LiveStreamingSection.vue' /* webpackChunkName: "components/templates-modern-more-info-section-live-streaming-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernMoreInfoSectionPhotoDocumentationSection = () => import('../../components/templates/modern/MoreInfoSection/PhotoDocumentationSection.vue' /* webpackChunkName: "components/templates-modern-more-info-section-photo-documentation-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernMoreInfoSection = () => import('../../components/templates/modern/MoreInfoSection/index.vue' /* webpackChunkName: "components/templates-modern-more-info-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernQuoteSection = () => import('../../components/templates/modern/QuoteSection/index.vue' /* webpackChunkName: "components/templates-modern-quote-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernStep = () => import('../../components/templates/modern/StepSection/Step.vue' /* webpackChunkName: "components/templates-modern-step" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernStepSection = () => import('../../components/templates/modern/StepSection/index.vue' /* webpackChunkName: "components/templates-modern-step-section" */).then(c => wrapFunctional(c.default || c))
export const TemplatesModernWeddingSection = () => import('../../components/templates/modern/WeddingSection/index.vue' /* webpackChunkName: "components/templates-modern-wedding-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
