import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Field Wajib di isi"
});

extend("email", {
  ...email,
  message: "Email tidak valid"
})

extend("confirmed", {
  ...confirmed,
  message: "Password tidak sama"
})
