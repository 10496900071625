import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c50ebc8 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _046e1618 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _4122468e = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _d93727c8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _3a6b62ea = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _71f86922 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1b8162f9 = () => interopDefault(import('../pages/offline-sorry.vue' /* webpackChunkName: "pages/offline-sorry" */))
const _e2f459b0 = () => interopDefault(import('../pages/our-team.vue' /* webpackChunkName: "pages/our-team" */))
const _5305fef0 = () => interopDefault(import('../pages/portofolio/index.vue' /* webpackChunkName: "pages/portofolio/index" */))
const _0a067801 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _50e7e30d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _1549e365 = () => interopDefault(import('../pages/terms-and-condition.vue' /* webpackChunkName: "pages/terms-and-condition" */))
const _734ea2bf = () => interopDefault(import('../pages/user-guides.vue' /* webpackChunkName: "pages/user-guides" */))
const _f42ad730 = () => interopDefault(import('../pages/connect/google.vue' /* webpackChunkName: "pages/connect/google" */))
const _693cadc8 = () => interopDefault(import('../pages/payment/status/index.vue' /* webpackChunkName: "pages/payment/status/index" */))
const _0e05bcbd = () => interopDefault(import('../pages/user/dashboard/index.vue' /* webpackChunkName: "pages/user/dashboard/index" */))
const _4342f112 = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _b92eacf6 = () => interopDefault(import('../pages/user/dashboard/Invitations.vue' /* webpackChunkName: "pages/user/dashboard/Invitations" */))
const _047aa255 = () => interopDefault(import('../pages/user/dashboard/guest/_title.vue' /* webpackChunkName: "pages/user/dashboard/guest/_title" */))
const _9e84d904 = () => interopDefault(import('../pages/user/dashboard/_title.vue' /* webpackChunkName: "pages/user/dashboard/_title" */))
const _76567d8a = () => interopDefault(import('../pages/create-invitation/_id.vue' /* webpackChunkName: "pages/create-invitation/_id" */))
const _4a4d6047 = () => interopDefault(import('../pages/payment/_slug.vue' /* webpackChunkName: "pages/payment/_slug" */))
const _64204789 = () => interopDefault(import('../pages/preview/_slug.vue' /* webpackChunkName: "pages/preview/_slug" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _08b4f1e0 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _7c50ebc8,
    name: "about-us"
  }, {
    path: "/contact-us",
    component: _046e1618,
    name: "contact-us"
  }, {
    path: "/error",
    component: _4122468e,
    name: "error"
  }, {
    path: "/faq",
    component: _d93727c8,
    name: "faq"
  }, {
    path: "/forgot-password",
    component: _3a6b62ea,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _71f86922,
    name: "login"
  }, {
    path: "/offline-sorry",
    component: _1b8162f9,
    name: "offline-sorry"
  }, {
    path: "/our-team",
    component: _e2f459b0,
    name: "our-team"
  }, {
    path: "/portofolio",
    component: _5305fef0,
    name: "portofolio"
  }, {
    path: "/privacy-policy",
    component: _0a067801,
    name: "privacy-policy"
  }, {
    path: "/register",
    component: _50e7e30d,
    name: "register"
  }, {
    path: "/terms-and-condition",
    component: _1549e365,
    name: "terms-and-condition"
  }, {
    path: "/user-guides",
    component: _734ea2bf,
    name: "user-guides"
  }, {
    path: "/connect/google",
    component: _f42ad730,
    name: "connect-google"
  }, {
    path: "/payment/status",
    component: _693cadc8,
    name: "payment-status"
  }, {
    path: "/user/dashboard",
    component: _0e05bcbd,
    name: "user-dashboard"
  }, {
    path: "/user/profile",
    component: _4342f112,
    name: "user-profile"
  }, {
    path: "/user/dashboard/Invitations",
    component: _b92eacf6,
    name: "user-dashboard-Invitations"
  }, {
    path: "/user/dashboard/guest/:title?",
    component: _047aa255,
    name: "user-dashboard-guest-title"
  }, {
    path: "/user/dashboard/:title",
    component: _9e84d904,
    name: "user-dashboard-title"
  }, {
    path: "/create-invitation/:id?",
    component: _76567d8a,
    name: "create-invitation-id"
  }, {
    path: "/payment/:slug?",
    component: _4a4d6047,
    name: "payment-slug"
  }, {
    path: "/preview/:slug?",
    component: _64204789,
    name: "preview-slug"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }, {
    path: "/:slug",
    component: _08b4f1e0,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
