import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faCheck as freeFasFaCheck,
  faTimes as freeFasFaTimes,
  faPhone as freeFasFaPhone,
  faChevronUp as freeFasFaChevronUp,
  faMapMarkerAlt as freeFasFaMapMarkerAlt,
  faDownload as freeFasFaDownload,
  faUsers as freeFasFaUsers,
  faCalendar as freeFasFaCalendar,
  faCheckCircle as freeFasFaCheckCircle,
  faPen as freeFasFaPen,
  faPlus as freeFasFaPlus,
  faAngleDown as freeFasFaAngleDown,
  faAngleUp as freeFasFaAngleUp,
  faEye as freeFasFaEye,
  faEyeSlash as freeFasFaEyeSlash,
  faPlay as freeFasFaPlay,
  faPause as freeFasFaPause,
  faCopy as freeFasFaCopy,
  faHome as freeFasFaHome,
  faTrash as freeFasFaTrash,
  faArrowLeft as freeFasFaArrowLeft,
  faSearch as freeFasFaSearch,
  faFilter as freeFasFaFilter,
  faLink as freeFasFaLink,
  faHeart as freeFasFaHeart,
  faCalendarCheck as freeFasFaCalendarCheck,
  faGift as freeFasFaGift,
  faBook as freeFasFaBook,
  faImages as freeFasFaImages
} from '@fortawesome/free-solid-svg-icons'

import {
  faEnvelope as freeFarFaEnvelope
} from '@fortawesome/free-regular-svg-icons'

import {
  faInstagram as freeFabFaInstagram,
  faYoutube as freeFabFaYoutube,
  faTiktok as freeFabFaTiktok,
  faWhatsapp as freeFabFaWhatsapp
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaCheck,
  freeFasFaTimes,
  freeFasFaPhone,
  freeFasFaChevronUp,
  freeFasFaMapMarkerAlt,
  freeFasFaDownload,
  freeFasFaUsers,
  freeFasFaCalendar,
  freeFasFaCheckCircle,
  freeFasFaPen,
  freeFasFaPlus,
  freeFasFaAngleDown,
  freeFasFaAngleUp,
  freeFasFaEye,
  freeFasFaEyeSlash,
  freeFasFaPlay,
  freeFasFaPause,
  freeFasFaCopy,
  freeFasFaHome,
  freeFasFaTrash,
  freeFasFaArrowLeft,
  freeFasFaSearch,
  freeFasFaFilter,
  freeFasFaLink,
  freeFasFaHeart,
  freeFasFaCalendarCheck,
  freeFasFaGift,
  freeFasFaBook,
  freeFasFaImages,
  freeFarFaEnvelope,
  freeFabFaInstagram,
  freeFabFaYoutube,
  freeFabFaTiktok,
  freeFabFaWhatsapp
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
