import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=0bc6df9f&scoped=true&"
var script = {}
import style0 from "./auth.vue?vue&type=style&index=0&id=0bc6df9f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc6df9f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NgondangIdLogo: require('/opt/build/repo/components/NgondangIdLogo.vue').default})
