//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      sosmedIcon: [
        {
          name: 'instagram',
          link: 'https://www.instagram.com/',
          icon: '/icon/footericon/instagram.svg'
        },
        {
          name: 'Tiktok',
          link: 'http://tiktok.com/@ngondangid',
          icon: '/icon/footericon/tiktok.svg'
        },
        {
          name: 'youtube',
          link: 'https://www.youtube.com/channel/UChJPWskAzfKP9ZJfDBQdaOw',
          icon: '/icon/footericon/youtube.svg'
        },
        // {
        //   name: 'facebook',
        //   link: 'https://www.facebook.com/',
        //   icon: '/icon/footericon/facebook.svg'
        // },
        // {
        //   name: 'twitter',
        //   link: 'https://www.twitter.com/',
        //   icon: '/icon/footericon/twitter.svg'
        // },
      ],

      paymentMethods: [
        '/bank/bni.png',
        '/bank/bri.png',
        '/bank/mandiri.png',
        '/bank/permata.png',
        '/bank/gopay.png',
        '/bank/QRIS.png',
      ]
    }
  }
}
