export default function ({
  $axios,
  redirect,
  store,
  $auth,
  $config,
  error: nuxtError
}) {
  $axios.onRequest((config) => {
    config.headers.common.Accept = 'application/json'
    config.headers.common['Content-Type'] = 'application/json'
  })
  $axios.onError((error) => {
    // console.log(error)

    if (!error.response) {
        // redirect('/offline-sorry')
    } else if (error.response.data.statusCode === 401 && $auth !== undefined) {
      $auth.logout()
      // store.commit('setSessionDialog', true)
    } else  {
      // let message
      // if (error.response.data.message[0].messages === undefined) {
      //   message = error.response.data.message
      // } else {
      //   message = error.response.data.message[0].messages[0].message
      // }
      // console.log(error)
    }

  })
  $axios.onRequestError(err => {
    console.log("error", err)
  })

}
