export default ($config, $axios) => ({
    createTransaction (params) {
      return $axios.post(`/api/transactions`, params)
    },
    getTransactionByPaymentId (params) {
      return $axios.get(`/api/transactions/`+params)
    },
    getTransactions(params) {
      return $axios.get(`api/transactions/me?populate=%2A&createdAt%3Adesc`,{
        headers: {
          'Authorization': `Bearer bd5a04d3ed5dc2b5c8b022ed43959ee2c50e176d593a18a67fa481f3d0dc49485a61e00f1bc5428348477313ea8013a0de9c10e280f5209955e255536bcafce6702339392a0866098e747d8b3db97a52d4f4348eb5d9e4a45b49130df9cd054d6084540494ff3f19d133f5e16d9a48838982d323360f5c7c76fb84d51c482705`
        }
      })
    },
    checkVoucher(code){
      return $axios.get(`api/vouchers/` + code)
    }
  })
