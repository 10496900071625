import packageServices from '~/services/package'
import authServices from '~/services/auth'
import themeServices from '~/services/theme'
import invitationServices from '~/services/invitation'
import transactionServices from '~/services/transaction'
import guestServices from '~/services/guest'
import miscServices from '~/services/misc'
import partner from '~/services/partner'
import home from '~/services/home'

export default ({ $config, $axios }, inject) => {
  const allServices = {
    package: packageServices($config, $axios),
    auth_connect: authServices($config, $axios),
    theme: themeServices($config, $axios),
    invitation: invitationServices($config, $axios),
    transaction: transactionServices($config, $axios),
    guest: guestServices($config, $axios),
    misc: miscServices($config, $axios),
    partner: partner($config, $axios),
    home: home($config, $axios)
  }

  const methods = Object.keys(allServices)
  methods.forEach((method) => {
    inject(method, allServices[method])
  })
}
