//
//
//
//
//
//
//
//

export default {
  layout: 'default'
}
