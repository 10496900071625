export default ($config, $axios) => ({
  getCategories () {
    return $axios.get(`/api/invitation-categories`)
  },
  createInvitation (params) {
    return $axios.post(`/api/invitations`, params )
  },
  updateInvitation (params, id) {
    return $axios.put(`/api/invitations/`+id, params )
  },
  upload (params, onUploadProgress) {
    return $axios.post(`/api/upload`, params,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }, onUploadProgress})
  },
  deleteUploadedImage (params) {
    return $axios.delete(`/api/upload/files/`+params)
  },
  getMusicList (params) {
    const hasFilter = params.category ? `&filters[invitation_category][name][$eq]=${params.category}`: ''
    const pageSize = params.pageSize ? params.pageSize : 1000
    const page = params.page ? params.page : 1
    return $axios.get(`/api/musics?populate=%2A${hasFilter}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`)
  },
  getInvitationByUser () {
    return $axios.get(`/api/invitations/list`)
  },
  getInvitation (title, guest) {
    const encodeGuest = guest ? encodeURIComponent(guest): undefined
    return $axios.get(`/api/invitations/${title}${encodeGuest !== null && encodeGuest !== undefined ? '?guest='+ encodeGuest : ''}` , {
      // headers: {
      //   'Authorization': `Bearer ${$config.apiToken}`
      // }
    })
  },
  getInvitationByTitle (title) {
    return $axios.get(`/api/invitations/dashboard/${title}`)
  },
  deleteInvitation (id) {
    return $axios.delete(`/api/invitations/`+id)
  },
  getPortofolio() {
    return $axios.get(`/api/invitations?populate=%2A&createdAt%3Adesc`)
  }
})
